<template>
  <div class="pe-3 mb-md-5 mb-4">
    <div>
      <b-skeleton-img aspect="8:9"></b-skeleton-img>
    </div>
    <div class="mt-2 d-flex">
      <div class="col-7">
        <b-skeleton></b-skeleton>
        <b-skeleton class="me-5"></b-skeleton>
      </div>
      <div class="col-4 ms-auto">
        <b-skeleton></b-skeleton>
        <b-skeleton></b-skeleton>
      </div>
    </div>
  </div>
</template>
<script>
import { BSkeletonImg, BSkeleton } from 'bootstrap-vue'
export default {
  components: { BSkeletonImg, BSkeleton },
}
</script>
