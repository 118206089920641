var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"title":'Filtro de ' + _vm.title.toLowerCase()}},[(_vm.isMovile)?_c('div',[_c('div',{staticClass:"filter-tag"},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('filtre-' + _vm.indexMovil),expression:"'filtre-' + indexMovil"}],staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.title)+" ")]),_c('img',{staticClass:"ms-3",attrs:{"src":require("@/assets/icons/arrow-select.svg"),"alt":""}})]),(_vm.filtersSelecteds.length > 0)?_c('span',{staticClass:"n-options-filter-select"},[_vm._v(_vm._s(_vm.filtersSelecteds.length))]):_vm._e()]),_c('b-sidebar',{attrs:{"id":'filtre-' + _vm.indexMovil,"right":"","no-header":"","width":"100%","shadow":""},scopedSlots:_vm._u([{key:"default",fn:function({ hide }){return [_c('div',{staticClass:"header-filter-movil"},[_c('h3',[_vm._v("Filtros")]),_c('span',{on:{"click":function($event){_vm.filtersSelecteds = []}}},[_vm._v("Limpiar")])]),_c('div',{staticClass:"header-filter-info-movil",on:{"click":function($event){_vm.addFilter('', false)
            hide()}}},[_c('img',{staticClass:"me-3",attrs:{"src":require("@/assets/icons/arrow-left.svg"),"alt":""}}),_c('p',{staticClass:"mb-0",attrs:{"title":_vm.title}},[_vm._v(_vm._s(_vm.title))])]),_c('div',{staticClass:"box-filter-movil"},_vm._l((_vm.filters),function(filter,index){return _c('div',{key:index,staticClass:"filter-one-level-router",on:{"click":function($event){return _vm.addFilter(filter, true)}}},[_c('div',{staticClass:"filter-tag pe-3 justify-content-start",style:(_vm.filtersSelecteds.includes(filter.slug)
                  ? 'background-color: #f2f2f2;'
                  : '')},[_c('span',{style:(_vm.filtersSelecteds.includes(filter.slug)
                    ? `font-family: 'PT Sans Bold', sans-serif;`
                    : '')},[_vm._v(_vm._s(filter.text .toLowerCase() .replace(/^\w/, d => d.toUpperCase())))]),(filter.cantidad > 0)?_c('p',{staticClass:"cantidad-filter"},[_vm._v(" ("+_vm._s(filter.cantidad)+") ")]):_vm._e(),(_vm.filtersSelecteds.includes(filter.slug))?_c('img',{staticClass:"img-check-filter-movil",staticStyle:{"transform":"translate(0)"},attrs:{"src":require("@/assets/icons/icon-check-black.svg"),"alt":""}}):_vm._e()])])}),0),_c('div',{staticClass:"box-btn-apply-filter-movile"},[_c('button',{staticClass:"btn-apply-filter-movile",on:{"click":function($event){_vm.$store.state.products.showSidebar = false
              _vm.addFilter('', false)}}},[_vm._v(" Aplicar filtros ")])])]}}],null,false,1938018513)})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }