<template>
  <div
    id="nav-filters-desktop"
    :style="$store.state.products.showSidebar ? 'z-index: 2000;' : ''"
  >
    <!-- <div class="filters-store d-none container-max-width">
            <div class="filters" style="">
                <div class="py-1 col-md-4 col-xl-3" v-if="filtersCategoria.length > 0">
                    <filter-one-level :filters="filtersCategoria" :title="'Categoría de producto'"/>
                </div>
                <div class="py-1 col-md-4 col-xl-3" v-if="filtersSubcategoria.length > 0">
                    <filter-one-level :filters="filtersSubcategoria" :title="'Tipo de producto'"/>
                </div>
                <div class="py-1 col-md-4 col-xl-3" v-if="filtersColor.length > 0">
                    <filter-color :filters="filtersColor"/>
                </div>
                <div
                    v-if="filtersSize.length > 0"
                    class="py-1 col-md-4 col-xl-3"
                    :class="showMoreFilters ? 'd-block' : (filtersCategoria.length == 0) ? 'd-block' : 'd-md-none d-xl-block'">
                    <filter-size :filters="filtersSize"/>
                </div>
                <div 
                    v-if="filtersPrecio.length > 0"
                    class="py-1 col-md-4 col-xl-3"
                    :style="showMoreFilters ? 'display: block !important' : 'display: none'"
                    :class="filtersPrecio.length > 0 && filtersCategoria.length == 0 ? filtersSubcategoria.length == 0 ? 'd-block' : 'd-md-none d-xl-block' : 'd-none'">
                    <filter-price :filters="filtersPrecio"/>
                </div>
                <div
                    v-if="filtersDiscounts.length > 0"
                    class="py-1 col-md-4 col-xl-3"
                    :style="showMoreFilters ? 'display: block !important' : 'display: none'"
                    :class="filtersDiscounts.length > 0 && filtersSubcategoria.length == 0 ? 'd-md-none d-xl-block' : 'd-none'">
                    <filter-multi-select :filters="filtersDiscounts" :indexMovil="'descuentos'" :title="'Descuentos'"/>
                </div>
                <div
                    :key="index"
                    v-for="(filter, index) in filtersMultiSelect"
                    class="py-1 col-md-4 col-xl-3">
                    <filter-multi-select :filters="filter.items" :indexMovil="filter.query_name" :title="filter.title"/>
                </div>
                <div class="py-1 col-md-4 col-xl-3" v-if="filterCollections.length > 0"
                :style="showMoreFilters ? 'display: block !important' : 'display: none'">
                    <filter-one-level :filters="filterCollections" :title="'Colecciones'"/>
                </div>
            </div>
            <div class="options-filters" :style="showMoreFilters ? 'align-items: flex-start; margin-top: .65rem;' : ''">
                <button class="more-filters" v-if="showBTNMoreFilters >= 0" @click="showMoreFilters = !showMoreFilters">{{showMoreFilters ? 'Menos' : 'Más'}} filtros</button>
                <div v-else/>
                <div style="margin-top: .155rem;">
                    <img v-if="gridDisplay == 6" src="@/assets/icons/display-2-select.svg" class="pe-2" alt="" style="cursor: pointer;">
                    <img v-else src="@/assets/icons/display-2.svg" @click="$emit('changeGridDisplay', 6)" class="pe-2" alt="" style="cursor: pointer;">
                    <img v-if="gridDisplay === 4" src="@/assets/icons/display-3-select.svg" class="pe-2" alt="" style="cursor: pointer;">
                    <img v-else src="@/assets/icons/display-3.svg" @click="$emit('changeGridDisplay', 4)" class="pe-2" alt="" style="cursor: pointer;">
                    <select style="margin-top: .075rem;" class="filter-order-by" v-model="orderBy">
                        <option :value="null">Ordenar por</option>
                        <option value="date">Novedades</option>
                        <option value="price_asc">Precio más bajo</option>
                        <option value="price_desc">Precio más alto</option>
                        <option value="best_seller">Lo más vendido</option>
                        <option value="best_visit">Lo más visto</option>
                        <option value="sale">Descuentos</option>
                    </select>
                </div>
            </div>
        </div> -->
    <div v-if="!loaderFilters" class="filters-store-movil-content ps-3 ps-md-0">
      <div
        v-if="atajos.length > 0"
        :key="atajos.length + $route.fullPath"
        class="container-carousel-atajos"
      >
        <carousel
          :dots="false"
          :lazy-load="true"
          :nav="false"
          :rewind="false"
          :auto-width="true"
        >
          <div
            v-for="(item, index) in atajos"
            :key="index + $route.fullPath"
            class="item-carousel-category"
          >
            <div class="carousel-category">
              <router-link
                :to="
                  item.query_name
                    ? { query: { [item.query_name]: item.slug } }
                    : { path: item.slug }
                "
                :style="
                  '/' + item.slug == $route.path ||
                  ($route.query[item.query_name] &&
                    $route.query[item.query_name]
                      .split(',')
                      .includes(item.slug))
                    ? 'font-family: `PT Sans Bold`, sans-serif;'
                    : ''
                "
              >
                {{
                  item.text.toLowerCase().replace(/^\w/, d => d.toUpperCase())
                }}</router-link
              >
            </div>
          </div>
        </carousel>
      </div>
      <div class="filters-store-movil ms-auto me-3">
        <button
          v-b-toggle.sidebar-menu-movil
          @click="
            showMoreFilters = true
            $store.state.displayBtnHelp = false
          "
        >
          <img
            class="pe-2"
            style="height: 25px"
            src="@/assets/icons/icon-filter.svg"
            alt="icono filtrar"
          />
          Filtrar
        </button>
      </div>
      <show-filters-movile />
    </div>
    <div v-else class="skeleton-filters">
      <carousel
        style="width: calc(100% - 146px)"
        class="ps-2 ps-md-0"
        :dots="false"
        :lazy-load="true"
        :nav="false"
        :rewind="false"
        :auto-width="true"
      >
        <b-skeleton
          v-for="index in 13"
          :key="index"
          :width="index % 2 == 0 ? '80px' : '120px'"
          height="57px"
          class="me-2"
        ></b-skeleton>
      </carousel>
      <b-skeleton width="106px" height="57px" class="ms-4 me-3"></b-skeleton>
    </div>
  </div>
</template>
<script>
// import FilterSize from '@/componentssFiltersDesktop/FilterSize'
// import FilterPrice from '@/components/store/FiltersDesktop/FilterPrice'
// import FilterColor from '@/components/store/FiltersDesktop/FilterColor'
// import FilterOneLevel from '@/components/store/FiltersDesktop/FilterOneLevel'
import ShowFiltersMovile from '@/components/store/FiltersMovile/ShowFiltersMovile'
import { BSkeleton } from 'bootstrap-vue'
// import FilterMultiSelect from '@/components/store/FiltersDesktop/FilterMultiSelect'

import carousel from 'v-owl-carousel'
import { mapState } from 'vuex'
export default {
  // Se mantienen los filtros comentados por que se cambio el diseño para desktop
  components: {
    ShowFiltersMovile,
    /* FilterColor, FilterSize, FilterPrice, FilterMultiSelect, FilterOneLevel, */ carousel,
    BSkeleton,
  },
  props: {
    gridDisplay: {
      type: Number,
      required: false,
      default: 6,
    },
  },
  data() {
    return {
      showMoreFilters: false,
      orderBy: null,
    }
  },
  computed: {
    ...mapState('products', [
      'loaderFilters',
      'atajos',
      'filtersColor',
      'filtersPrecio',
      'filtersSize',
      'filtersCategoria',
      'filtersSubcategoria',
      'filtersDiscounts',
      'filtersOneLevel',
      'filtersMultiSelect',
      'filtersOneLevel',
      'filterCollections',
    ]),
    showBTNMoreFilters() {
      let count = 0

      return count
    },
  },
  watch: {
    orderBy() {
      let query = Object.assign({}, this.$route.query)
      delete query.page
      if (this.orderBy) {
        query.ordenar = this.orderBy
        this.$router.push({ query: query })
      } else {
        delete query.ordenar
        this.$router.push({ query: query })
      }
    },
  },
  methods: {
    // navigateURL(url){
    //     let baseurl = ''
    //     this.atajos.find(e => {
    //         console.log(this.$route.path.slice(1, this.$route.path.length).split('-').includes(e.slug), ' 1')
    //         console.log(this.$route.path.slice(1, this.$route.path.length).includes(e.slug), ' 2')
    //         console.log(url.split('-').includes(this.$route.path.slice(1, this.$route.path.length)), url, this.$route.path.slice(1, this.$route.path.length), ' 3')
    //         return false
    //     })
    //     if(this.$route.params.category && url.split('-').includes(this.$route.params.category)){
    //         console.log('Hola 1')
    //         if(this.$route.params.subcategory && url.split('-').includes(this.$route.params.subcategory)){
    //             console.log('Hola 1.2')
    //         }else{
    //         }
    //     }else if(this.$route.params.subcategory && url.split('-').includes(this.$route.params.subcategory)){
    //         console.log('Hola 2')
    //     }else if(this.$route.params.wildcard && url.split('-').includes(this.$route.params.wildcard)){
    //         console.log('Hola 3')
    //     }else{
    //         console.log('Hola 4')
    //     }
    //     if(this.atajos.find(e => this.$route.path.slice(1, this.$route.path.length).split('-').includes(e.slug))){
    //         console.log('Hola1')
    //     }else if(this.atajos.find(e => this.$route.path.slice(1, this.$route.path.length).includes(e.slug))){
    //         console.log('Hola2')
    //     }else if(url.split('-').includes(this.$route.path.slice(1, this.$route.path.length))){
    //         console.log('Hola3')
    //     }else{
    //         console.log('Hola4', url)
    //         url += '-' + this.$route.path.slice(1, this.$route.path.length)
    //     }
    //     return url
    // }
  },
}
</script>
