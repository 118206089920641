<template>
  <div class="filter-container" :title="'Filtro de ' + title.toLowerCase()">
    <div v-if="isMovile">
      <div class="filter-tag">
        <div v-b-toggle="'filtre-' + indexMovil" class="d-flex">
          <span>{{ title }} </span>
          <img class="ms-3" src="@/assets/icons/arrow-select.svg" alt="" />
        </div>
        <span v-if="filterSelected.length" class="n-options-filter-select">{{
          filterSelected.length
        }}</span>
      </div>
      <b-sidebar
        :id="'filtre-' + indexMovil"
        right
        no-header
        width="100%"
        shadow
      >
        <template v-slot:default="{ hide }">
          <div class="header-filter-movil">
            <h3>Filtros</h3>
            <span @click="filterSelected = []"
              >Limpiar
              <!-- <img @click="hide" class="close-filters-movile" src="@/assets/icons/delete-gray-icon.svg" alt="close sidebar"> --></span
            >
          </div>
          <div
            class="header-filter-info-movil"
            @click="
              addFilter('', false)
              hide()
            "
          >
            <img class="me-3" src="@/assets/icons/arrow-left.svg" alt="" />
            <p class="mb-0">{{ title }}</p>
          </div>
          <div class="box-filter-movil">
            <b-form-group
              v-slot="{ ariaDescribedby }"
              class="content-input-multi-select-filter"
            >
              <b-form-checkbox-group
                :id="'multi-select-filter-' + indexMovil"
                v-model="filterSelected"
                class="input-checkbox-filter"
                :options="filters"
                :aria-describedby="ariaDescribedby"
                :name="'multi-select-filter-' + indexMovil"
              ></b-form-checkbox-group>
            </b-form-group>
          </div>
          <div class="box-btn-apply-filter-movile">
            <button
              class="btn-apply-filter-movile"
              @click="
                $store.state.products.showSidebar = false
                addFilter()
              "
            >
              Aplicar filtros
            </button>
          </div>
        </template>
      </b-sidebar>
    </div>
  </div>
</template>
<script>
import { BFormCheckboxGroup } from 'bootstrap-vue'
import { mapState } from 'vuex'

export default {
  components: { BFormCheckboxGroup },
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: 'Filtro',
    },
    indexMovil: {
      type: String,
      required: false,
      default: 'filtro',
    },
    isMovile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      filterSelected: [],
      tempFilterSelected: [],
      showOptions: false,
      clickInside: false,
      options: [{ text: '', value: '' }],
    }
  },
  computed: {
    ...mapState('products', ['showSidebar']),
  },
  watch: {
    showSidebar() {
      if (
        !this.showSidebar &&
        this.tempFilterSelected.toString() != this.filterSelected.toString()
      ) {
        this.addFilter()
      }
    },
    filterSelected() {
      let temp = {
        slug: this.indexMovil,
        data: this.filterSelected ? this.filterSelected : [],
      }
      this.$emit('updateFilterMovil', temp)
    },
    showOptions() {
      if (this.showOptions) {
        setTimeout(() => {
          this.clickInside = true
        }, 100)
      } else {
        this.clickInside = false
      }
    },
    clickInside() {
      if (!this.clickInside && this.showOptions) {
        setTimeout(() => {
          this.clickInside = true
        }, 100)
      }
    },
    $route() {
      this.filterSelected = this.$route.query[this.indexMovil]
        ? this.$route.query[this.indexMovil].split(',')
        : []
      this.tempFilterSelected = this.$route.query[this.indexMovil]
        ? this.$route.query[this.indexMovil].split(',')
        : []
    },
  },
  mounted() {
    this.filterSelected = this.$route.query[this.indexMovil]
      ? this.$route.query[this.indexMovil].split(',')
      : []
    this.tempFilterSelected = this.$route.query[this.indexMovil]
      ? this.$route.query[this.indexMovil].split(',')
      : []
    let elThis = this
    document.addEventListener('click', () => {
      if (elThis.showOptions && elThis.clickInside) {
        elThis.showOptions = false
      }
    })
  },
  methods: {
    async clearFilter() {
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query[this.indexMovil]
      await this.$router.push({ params: params, query: query }).catch(() => {})
    },
    async addFilter() {
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.page
      if (this.filterSelected.length == 0) {
        delete query[this.indexMovil]
      } else {
        query[this.indexMovil] = this.filterSelected.toString()
      }
      this.showOptions = false
      setTimeout(() => {
        this.clickInside = false
      }, 100)
      await this.$router.push({ params: params, query: query }).catch(() => {})
    },
  },
}
</script>
