<template>
  <div class="container-filters-select container-max-width">
    <div v-for="(tag, index) in tagsFilters" :key="index + 'tag'">
      <div v-if="!tag.name.toLowerCase().includes('utm')" class="tag-filter">
        <p>{{ tag.name }}</p>
        <img
          src="@/assets/icons/close-white.svg"
          alt="delete"
          @click="deleteFilter(tag)"
        />
      </div>
    </div>
    <div v-if="tagsFilters.length > 0" class="tag-filter">
      <p>Borrar todo</p>
      <img
        src="@/assets/icons/close-white.svg"
        alt="delete"
        @click="$router.push({ name: 'Store' })"
      />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      tagsFilters: [],
    }
  },
  computed: {
    ...mapState('products', [
      'tallas_todo',
      'subcategorias_todo',
      'colores_todo',
      'endLoadFilters',
      'filtersMultiSelect',
      'filtersOneLevel',
    ]),
  },
  watch: {
    $route() {
      this.updateTags()
    },
    endLoadFilters() {
      this.updateTags()
    },
  },
  methods: {
    updateTags() {
      var isPrice = false
      this.tagsFilters = []
      let keysFilters =
        this.$route.query != {}
          ? Object.keys(this.$route.query).filter(function(e) {
              return e !== 'page'
            })
          : []
      keysFilters.forEach(e => {
        this.$route.query[e].split(',').forEach(f => {
          switch (e) {
            case 'tallas':
              this.tagsFilters.push({
                name:
                  'Talla: ' +
                  this.tallas_todo
                    .find(talla => talla.id == f)
                    .descripcion.toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase()),
                tag: f,
                typeFilter: e,
              })
              break
            case 'colores':
              this.tagsFilters.push({
                name: this.colores_todo
                  .find(talla => talla.id == f)
                  .descripcion.toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase()),
                tag: f,
                typeFilter: e,
              })
              break
            case 'precios':
              this.tagsFilters.push({
                name:
                  (isPrice ? 'Hasta: $' : 'Desde: $') +
                  new Intl.NumberFormat('de-DE').format(f),
                tag: f,
                typeFilter: e,
              })
              isPrice = true
              break
            case 'descuentos':
              this.tagsFilters.push({ name: f + '%', tag: f, typeFilter: e })
              break
            case 'colecciones':
              this.tagsFilters.push({ name: f, tag: f, typeFilter: e })
              break
            default: {
              let tagName = ''
              switch (f) {
                case 'date':
                  tagName = 'novedades'
                  break
                case 'price_asc':
                  tagName = 'precio más bajo'
                  break
                case 'price_desc':
                  tagName = 'precio más alto'
                  break
                case 'best_seller':
                  tagName = 'lo más vendido'
                  break
                case 'best_visit':
                  tagName = 'lo más visto'
                  break
                case 'sale':
                  tagName = 'Descuentos'
                  break
                default: {
                  let tempData = this.filtersMultiSelect.find(
                    ele => ele.query_name == e,
                  )
                  let dataFilter = tempData
                    ? tempData.items.find(dataElement => dataElement.slug == f)
                    : null
                  tagName = dataFilter ? dataFilter.text : e
                }
              }
              this.tagsFilters.push({
                name: '' + tagName,
                tag: f,
                typeFilter: e,
              })
            }
          }
        })
      })
      if (this.$route.params.category) {
        this.tagsFilters.push({
          name: this.$route.params.category
            .toLowerCase()
            .replace(/^\w/, d => d.toUpperCase()),
          tag: this.$route.params.category,
          typeFilter: 'category',
        })
      }
      if (this.$route.params.subcategory) {
        if (this.$route.params.subcategory.split('_').length > 1) {
          this.$route.params.subcategory.split('_').forEach(element => {
            this.tagsFilters.push({
              name: element.toLowerCase().replace(/^\w/, d => d.toUpperCase()),
              tag: element,
              typeFilter: 'subcategory',
            })
          })
        } else {
          this.tagsFilters.push({
            name: this.$route.params.subcategory
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase()),
            tag: this.$route.params.subcategory,
            typeFilter: 'subcategory',
          })
        }
      }
      if (this.$route.params.wildcard) {
        this.tagsFilters.push({
          name: this.$route.params.wildcard
            .toLowerCase()
            .replace(/^\w/, d => d.toUpperCase()),
          tag: this.$route.params.wildcard,
          typeFilter: 'wildcard',
        })
      }
    },
    async deleteFilter(tag) {
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.page
      if (query[tag.typeFilter]) {
        query[tag.typeFilter] =
          query[tag.typeFilter].replace(tag.tag + ',', '') ==
          query[tag.typeFilter]
            ? query[tag.typeFilter].replace(',' + tag.tag, '') ==
              query[tag.typeFilter]
              ? query[tag.typeFilter].replace(tag.tag, '')
              : query[tag.typeFilter].replace(',' + tag.tag, '')
            : query[tag.typeFilter].replace(tag.tag + ',', '')
        query[tag.typeFilter] == '' || tag.typeFilter == 'precios'
          ? delete query[tag.typeFilter]
          : ''
        await this.$router.push({ params: params, query: query })
      } else {
        if (tag.typeFilter == 'wildcard') {
          await this.$router.push({
            name: 'TiendaSubcategory',
            params: {
              category: params.category,
              subcategory: params.subcategory,
            },
          })
        } else if (tag.typeFilter == 'subcategory') {
          if (params.subcategory.split('_').length > 1) {
            let subcategoryRouter = ''
            if (params.subcategory.split(tag.tag + '_') > 1) {
              subcategoryRouter = params.subcategory.replace(tag.tag + '_', '')
            } else {
              subcategoryRouter = params.subcategory.replace('_' + tag.tag, '')
            }
            await this.$router.push({
              name: 'TiendaSubcategory',
              params: {
                category: params.category,
                subcategory: subcategoryRouter,
              },
            })
          } else {
            if (params.wildcard) {
              await this.$router.push({
                name: 'TiendaSubcategory',
                params: {
                  category: params.category,
                  subcategory: params.wildcard,
                },
              })
            } else {
              await this.$router.push({
                name: 'TiendaCategory',
                params: { category: params.category },
              })
            }
          }
        } else if (tag.typeFilter == 'category') {
          if (params.wildcard) {
            await this.$router.push({
              name: 'TiendaCategory',
              params: { category: params.wildcard },
            })
          } else if (params.subcategory) {
            if (
              this.subcategorias_todo.find(e => e.value == params.subcategory)
            ) {
              await this.$router.push({ name: 'Store' })
            } else {
              await this.$router.push({
                name: 'TiendaCategory',
                params: { category: params.subcategory },
              })
            }
          } else {
            await this.$router.push({ name: 'Store' })
          }
        }
      }
    },
  },
}
</script>
