<template>
  <div class="filter-container" title="Filtro de precios">
    <div v-if="isMovile">
      <div class="filter-tag">
        <div v-b-toggle="'filtre-precio'" class="d-flex">
          <span>Precios</span>
          <img class="ms-3" src="@/assets/icons/arrow-select.svg" alt="" />
        </div>
        <span
          v-if="
            !(rangeSelected[0] == filters[0] && rangeSelected[1] == filters[1])
          "
          class="n-options-filter-select"
          >*</span
        >
      </div>
      <b-sidebar :id="'filtre-precio'" no-header right width="100%" shadow>
        <template v-slot:default="{ hide }">
          <div class="header-filter-movil">
            <h3>Filtros</h3>
            <span @click="rangeSelected = filters">Limpiar</span>
          </div>
          <div
            class="header-filter-info-movil"
            @click="
              addFilter()
              hide()
            "
          >
            <img class="me-3" src="@/assets/icons/arrow-left.svg" alt="" />
            <p class="mb-0">Precio</p>
          </div>
          <div class="price-filter-movil mt-4">
            <p class="mb-0">
              ${{ new Intl.NumberFormat('de-DE').format(rangeSelected[0]) }} -
              ${{ new Intl.NumberFormat('de-DE').format(rangeSelected[1]) }}
            </p>
          </div>
          <div class="box-filter-movil">
            <div class="container-range-price">
              <vue-slider
                ref="sliderprice"
                :key="keyFilter"
                v-model="rangeSelected"
                :dot-size="20"
                :min-range="50000"
                :interval="interval"
                :min="parseInt(filters[0])"
                :max="parseInt(filters[1])"
                :enable-cross="false"
                :process-style="{ backgroundColor: '#654062' }"
                :tooltip-style="{
                  backgroundColor: '#654062',
                  borderColor: '#654062',
                }"
              >
                <template v-slot:dot="{ focus }">
                  <div :class="['custom-dot', { focus }]"></div>
                </template>
              </vue-slider>
            </div>
            <div class="px-4">
              <button
                v-if="
                  parseInt(filters[0]) < 100000 && parseInt(filters[1]) > 100000
                "
                class="secondary-button mb-3 w-100"
                @click="changeMax('100000')"
              >
                Hasta $100.000
              </button>
              <button
                v-if="
                  parseInt(filters[0]) < 200000 && parseInt(filters[1]) > 200000
                "
                class="secondary-button mb-3 w-100"
                @click="changeMax('200000')"
              >
                Hasta $200.000
              </button>
              <button
                v-if="
                  parseInt(filters[0]) < 300000 && parseInt(filters[1]) > 300000
                "
                class="secondary-button mb-3 w-100"
                @click="changeMax('300000')"
              >
                Hasta $300.000
              </button>
              <button
                v-if="
                  parseInt(filters[0]) < 400000 && parseInt(filters[1]) > 400000
                "
                class="secondary-button mb-3 w-100"
                @click="changeMax('400000')"
              >
                Hasta $400.000
              </button>
              <button
                v-if="
                  parseInt(filters[0]) < 500000 && parseInt(filters[1]) > 500000
                "
                class="secondary-button mb-3 w-100"
                @click="changeMax('500000')"
              >
                Hasta $500.000
              </button>
            </div>
          </div>
          <div class="box-btn-apply-filter-movile">
            <button
              class="btn-apply-filter-movile"
              @click="
                $store.state.products.showSidebar = false
                addFilter()
              "
            >
              Aplicar filtros
            </button>
          </div>
          <!-- <img @click="hide" class="close-filters-movile" src="@/assets/icons/delete-gray-icon.svg" alt=""> -->
        </template>
      </b-sidebar>
    </div>
    <div v-else>
      <div class="select-filter" @click="showOptions = !showOptions">
        <span class="d-flex"
          >Precios
          <span
            v-if="
              !(
                parseInt(rangeSelected[0]) == filters[0] &&
                parseInt(rangeSelected[1]) == filters[1]
              )
            "
            class="n-options-filter-select-desktop"
            >*</span
          ></span
        >
        <img src="@/assets/icons/arrow-select.svg" alt="" />
      </div>
      <div
        v-if="showOptions"
        class="display-options-filter mt-4"
        style="overflow-x: visible"
        @click="clickInside = false"
      >
        <div class="header-filter-option">
          <p>
            ${{
              new Intl.NumberFormat('de-DE').format(parseInt(rangeSelected[0]))
            }}
            - ${{
              new Intl.NumberFormat('de-DE').format(parseInt(rangeSelected[1]))
            }}
          </p>
        </div>
        <div class="container-range-price">
          <vue-slider
            ref="sliderprice"
            :key="keyFilter"
            v-model="rangeSelected"
            :dot-size="20"
            :interval="interval"
            :min="parseInt(filters[0])"
            :max="parseInt(filters[1])"
            :enable-cross="false"
            :process-style="{ backgroundColor: '#654062' }"
            :tooltip-style="{
              backgroundColor: '#654062',
              borderColor: '#654062',
            }"
            @drag-end="
              addFilter()
              showOptions = false
            "
          >
            <template v-slot:dot="{ focus }">
              <div :class="['custom-dot', { focus }]"></div>
            </template>
          </vue-slider>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlider from 'vue-slider-component'
export default {
  components: { VueSlider },
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
    isMovile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      rangeSelected: [],
      tempRangeSelected: [],
      interval: 1,
      showOptions: false,
      clickInside: false,
      keyFilter: 0,
    }
  },
  watch: {
    filters() {
      if (this.filters) {
        this.interval = parseInt(Math.gcd(this.filters[0], this.filters[1]))
      }
      this.rangeSelected = this.$route.query.precios
        ? this.$route.query.precios.split(',')
        : this.filters.precios
      this.tempRangeSelected = this.$route.query.precios
        ? this.$route.query.precios.split(',')
        : this.filters.precios
      this.keyFilter++
    },
    showOptions() {
      if (this.showOptions) {
        setTimeout(() => {
          this.clickInside = true
        }, 100)
      } else {
        this.clickInside = false
      }
    },
    // clickInside(){
    //     if(!this.clickInside && this.showOptions){
    //         setTimeout(()=>{this.clickInside = true;}, 100)
    //     }
    // },
    rangeSelected() {
      if (this.rangeSelected) {
        if (
          parseInt(this.rangeSelected[0]) == this.filters[0] &&
          parseInt(this.rangeSelected[1]) == this.filters[1]
        ) {
          this.$emit('updateFilterMovil', [])
        } else {
          this.$emit('updateFilterMovil', this.rangeSelected)
        }
      } else {
        this.rangeSelected = this.$route.query.precios
          ? this.$route.query.precios.split(',')
          : this.filters
        this.tempRangeSelected = this.$route.query.precios
          ? this.$route.query.precios.split(',')
          : this.filters
      }
    },
  },
  mounted() {
    this.rangeSelected = this.$route.query.precios
      ? this.$route.query.precios.split(',')
      : this.filters
    this.tempRangeSelected = this.$route.query.precios
      ? this.$route.query.precios.split(',')
      : this.filters
    Math.gcd = function() {
      if (arguments.length == 2) {
        if (arguments[1] == 0) return arguments[0]
        else return Math.gcd(arguments[1], arguments[0] % arguments[1])
      } else if (arguments.length > 2) {
        var result = Math.gcd(arguments[0], arguments[1])
        for (var i = 2; i < arguments.length; i++)
          result = Math.gcd(result, arguments[i])
        return result
      }
    }
    let elThis = this
    document.addEventListener('click', () => {
      if (elThis.showOptions && elThis.clickInside) {
        elThis.showOptions = false
      }
    })
    this.$emit('updateFilterMovil', this.rangeSelected)
  },
  methods: {
    async addFilter() {
      if (this.rangeSelected.toString() != this.tempRangeSelected.toString()) {
        let query = Object.assign({}, this.$route.query)
        let params = Object.assign({}, this.$route.params)
        delete query.page
        if (
          this.rangeSelected[0] == this.filters[0] &&
          this.rangeSelected[1] == this.filters[1]
        ) {
          delete query.precios
        } else {
          query.precios = this.rangeSelected.toString()
        }
        await this.$router
          .push({ params: params, query: query })
          .catch(() => {})
      }
    },
    changeMax(value) {
      this.$refs.sliderprice.setValue([value, this.rangeSelected[0]])
      this.addFilter()
    },
  },
}
</script>
