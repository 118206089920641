<template>
  <b-sidebar
    id="sidebar-menu-movil"
    v-model="$store.state.products.showSidebar"
    :backdrop-variant="'asd'"
    right
    no-close-on-route-change
    no-header
    backdrop
  >
    <template #default="{ hide }">
      <div class="container-scroll-filter">
        <div class="head-filters">
          <h2 class="title-filter">Filtros</h2>
          <div class="d-flex flex-wrap align-items-center">
            <p class="clean-filters" @click="clearFilter()">Borrar filtros</p>
            <img
              class="close-filters-movile"
              src="@/assets/icons/delete-gray-icon.svg"
              alt="boton cerrar"
              @click="
                applyFilters()
                hide()
              "
            />
          </div>
        </div>
        <div class="content-filter">
          <short-by :is-movile="true" />
          <filter-one-level
            v-if="filtersCategoria.length > 0"
            :is-movile="true"
            :filters="filtersCategoria"
            :title="'Categoría de productos'"
            :index-movil="'categoria'"
            @closeSidebar="hide()"
          />
          <filter-one-level
            v-if="filtersSubcategoria.length > 0"
            :is-movile="true"
            :filters="filtersSubcategoria"
            :title="'Tipo de productos'"
            :index-movil="'subcategoria'"
            :multi-select="true"
            @closeSidebar="hide()"
          />
          <filter-custom
            v-if="filterFeature.length > 0"
            :filters="filterFeature"
            :title="'Destacados'"
            :is-movile="true"
            :index-movil="'destacados'"
            @closeSidebar="hide()"
          />
          <filter-size
            v-if="filtersSize.length > 0"
            :filters="filtersSize"
            :is-movile="true"
            @closeSidebar="hide()"
            @updateFilterMovil="arraySelectTallas = $event"
          />
          <filter-color
            v-if="filtersColor.length > 0"
            :filters="filtersColor"
            :is-movile="true"
            @closeSidebar="hide()"
            @updateFilterMovil="arraySelectColores = $event"
          />
          <filter-price
            v-if="filtersPrecio.length > 0"
            :filters="filtersPrecio"
            :is-movile="true"
            @closeSidebar="hide()"
            @updateFilterMovil="arraySelectPrecios = $event"
          />
          <filter-multi-select
            v-if="filtersDiscounts.length > 0"
            :filters="filtersDiscounts"
            :title="'Descuentos'"
            :is-movile="true"
            :index-movil="'descuentos'"
            @closeSidebar="hide()"
            @updateFilterMovil="arraySelectDescuentos = $event"
          />
          <filter-custom
            v-if="filterCollections.length > 0"
            :is-movile="true"
            :filters="filterCollections[0].items"
            :title="'Colecciones'"
            :index-movil="'colecciones'"
            :multi-select="true"
            @closeSidebar="hide()"
          />
          <filter-custom
            v-for="(filtro, index) in filtersMultiSelect"
            :key="index"
            :is-movile="true"
            :filters="filtro.items"
            :title="filtro.title"
            :index-movil="filtro.query_name"
            :multi-select="true"
            @closeSidebar="hide()"
          />
        </div>
      </div>
      <div class="box-btn-apply-filter-movile">
        <button
          class="btn-apply-filter-movile"
          @click="
            $store.state.products.showSidebar = false
            applyFilters()
            hide()
          "
        >
          Aplicar filtros
        </button>
      </div>
    </template>
  </b-sidebar>
</template>
<script>
import FilterSize from '@/components/store/FiltersDesktop/FilterSize'
import FilterColor from '@/components/store/FiltersDesktop/FilterColor'
import FilterPrice from '@/components/store/FiltersDesktop/FilterPrice'
import FilterOneLevel from '@/components/store/FiltersDesktop/FilterOneLevel'
import FilterMultiSelect from '@/components/store/FiltersDesktop/FilterMultiSelect'
import FilterCustom from '@/components/store/FiltersDesktop/FilterCustom'
import ShortBy from '@/components/store/FiltersDesktop/ShortBy'

import { mapState } from 'vuex'
export default {
  components: {
    FilterOneLevel,
    FilterColor,
    FilterSize,
    FilterPrice,
    FilterMultiSelect,
    ShortBy,
    FilterCustom,
  },
  data() {
    return {
      arraySelectTallas: [],
      arraySelectPrecios: [],
      arraySelectColores: [],
      arrayMultiSelect: [],
      arraySelectDescuentos: { data: [] },
      arraySelectDestacados: { data: [] },
    }
  },
  computed: {
    ...mapState('products', [
      'filtersColor',
      'filtersPrecio',
      'filtersSize',
      'filtersCategoria',
      'filtersSubcategoria',
      'filtersDiscounts',
      'filtersOneLevel',
      'filtersMultiSelect',
      'filterCollections',
      'showSidebar',
      'filterFeature',
    ]),
  },
  watch: {
    showSidebar(to, from) {
      if (!this.showSidebar) {
        setTimeout(() => {
          this.$store.state.displayBtnHelp = true
        }, 250)
      }
      if (from) {
        this.applyFilters()
      }
    },
  },
  methods: {
    async applyFilters() {
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)

      if (this.arraySelectColores.length > 0) {
        query.colores = this.arraySelectColores.toString()
      } else {
        delete query.colores
      }

      if (this.arraySelectTallas.length > 0) {
        query.tallas = this.arraySelectTallas.toString()
      } else {
        delete query.tallas
      }

      if (this.arraySelectPrecios.length > 0) {
        query.precios = this.arraySelectPrecios.toString()
      } else {
        delete query.precios
      }

      if (this.arraySelectDescuentos.data.length > 0) {
        query.descuentos = this.arraySelectDescuentos.data.toString()
      } else {
        delete query.descuentos
      }
      await this.$router.push({ params: params, query: query }).catch(() => {})
    },
    async clearFilter() {
      await this.$router.push({ name: 'Store', query: {} }).catch(() => {})
    },
  },
}
</script>
