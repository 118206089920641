<template>
  <div :title="'Filtro de ' + title.toLowerCase()">
    <div v-if="isMovile">
      <div class="filter-tag">
        <div v-b-toggle="'filtre-' + indexMovil" class="d-flex">
          <span>{{ title }} </span>
          <img class="ms-3" src="@/assets/icons/arrow-select.svg" alt="" />
        </div>
        <span
          v-if="filtersSelecteds.length > 0"
          class="n-options-filter-select"
          >{{ filtersSelecteds.length }}</span
        >
      </div>
      <b-sidebar
        :id="'filtre-' + indexMovil"
        right
        no-header
        width="100%"
        shadow
      >
        <template v-slot:default="{ hide }">
          <div class="header-filter-movil">
            <h3>Filtros</h3>
            <span @click="filtersSelecteds = []">Limpiar</span>
          </div>
          <div
            class="header-filter-info-movil"
            @click="
              addFilter('', false)
              hide()
            "
          >
            <img class="me-3" src="@/assets/icons/arrow-left.svg" alt="" />
            <p class="mb-0" :title="title">{{ title }}</p>
          </div>
          <div class="box-filter-movil">
            <div
              v-for="(filter, index) in filters"
              :key="index"
              class="filter-one-level-router"
              @click="addFilter(filter, true)"
            >
              <div
                class="filter-tag pe-3"
                :style="
                  filtersSelecteds.includes(filter.slug)
                    ? 'background-color: #f2f2f2'
                    : ''
                "
              >
                <span
                  :style="
                    filtersSelecteds.includes(filter.slug)
                      ? `font-family: 'PT Sans Bold', sans-serif;`
                      : ''
                  "
                  :title="
                    filter.text
                      .toLowerCase()
                      .replace(/^\w/, d => d.toUpperCase())
                  "
                  >{{
                    filter.text
                      .toLowerCase()
                      .replace(/^\w/, d => d.toUpperCase())
                  }}</span
                >
                <img
                  v-if="filtersSelecteds.includes(filter.slug)"
                  class="img-check-filter-movil"
                  style="transform: translate(0);"
                  src="@/assets/icons/icon-check-black.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="box-btn-apply-filter-movile">
            <button
              class="btn-apply-filter-movile"
              @click="
                $store.state.products.showSidebar = false
                addFilter('', false)
              "
            >
              Aplicar filtros
            </button>
          </div>
        </template>
      </b-sidebar>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'

export default {
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
    title: {
      type: String,
      required: false,
      default: 'Filtro',
    },
    indexMovil: {
      type: String,
      required: false,
      default: 'filtro',
    },
    isMovile: {
      type: Boolean,
      required: false,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      filtersSelecteds: [],
      dataTempFilters: [],
      showOptions: false,
      clickInside: false,
    }
  },
  computed: {
    ...mapState('products', ['showSidebar']),
  },
  watch: {
    showSidebar() {
      if (
        !this.showSidebar &&
        this.dataTempFilters.toString() != this.filtersSelecteds.toString()
      ) {
        this.addFilter('', false)
      }
    },
    showOptions() {
      if (this.showOptions) {
        setTimeout(() => {
          this.clickInside = true
        }, 100)
      } else {
        this.clickInside = false
      }
    },
    clickInside() {
      if (!this.clickInside && this.showOptions) {
        setTimeout(() => {
          this.clickInside = true
        }, 100)
      }
    },
    filters() {
      this.filtersSelecteds = []
      this.dataTempFilters = []
      let params = Object.assign({}, this.$route.params)
      let query = Object.assign({}, this.$route.query)
      if (
        params.category &&
        this.filters.find(e => e.slug == params.category)
      ) {
        this.filtersSelecteds.push(params.category)
      }
      if (query[this.indexMovil]) {
        this.filtersSelecteds = query[this.indexMovil].split(',')
        this.dataTempFilters = query[this.indexMovil].split(',')
      }
    },
  },
  mounted() {
    let elThis = this
    document.addEventListener('click', () => {
      if (elThis.showOptions && elThis.clickInside) {
        elThis.showOptions = false
      }
    })
    let params = Object.assign({}, this.$route.params)
    let query = Object.assign({}, this.$route.query)
    if (params.category && this.filters.find(e => e.slug == params.category)) {
      this.filtersSelecteds.push(params.category)
      this.dataTempFilters.push(params.category)
    }
    if (query[this.indexMovil]) {
      this.filtersSelecteds = query[this.indexMovil].split(',')
      this.dataTempFilters = query[this.indexMovil].split(',')
    }
  },
  methods: {
    async addFilter(filtro, sidebar) {
      if (sidebar) {
        if (this.multiSelect) {
          if (this.filtersSelecteds.includes(filtro.slug)) {
            this.filtersSelecteds = this.filtersSelecteds.filter(
              e => e != filtro.slug,
            )
          } else {
            this.filtersSelecteds.push(filtro.slug)
          }
        } else {
          this.filtersSelecteds = [filtro.slug]
        }
      } else {
        let params = Object.assign({}, this.$route.params)
        let query = Object.assign({}, this.$route.query)
        if (this.multiSelect) {
          if (this.filtersSelecteds.includes(params.category)) {
            this.filtersSelecteds.push(params.category)
          }
        }
        if (this.filtersSelecteds.length > 0) {
          query[this.indexMovil] = this.filtersSelecteds.toString()
        } else {
          delete query[this.indexMovil]
        }
        this.$router.push({ query: query })
      }
    },
    getRoute(filter) {
      if (
        this.$route.params.category &&
        filter.slug.split('-')[0] == this.$route.params.category
      ) {
        if (this.$route.params.subcategory) {
          return { path: filter.slug + '-' + this.$route.params.subcategory }
        } else {
          return { path: filter.slug }
        }
      } else {
        if (this.$route.params.category) {
          return { path: this.$route.path + '-' + filter.slug }
        } else {
          return { path: filter.slug }
        }
      }
    },
    concatSubcategorias() {
      if (this.filtersSelecteds.length > 0) {
        let text = ''
        this.filtersSelecteds.sort().forEach(element => {
          if (element.split('-').length > 1) {
            text += element.split('-')[1] + '_'
          } else {
            text += element + '_'
          }
        })
        return text.substring(0, text.length - 1)
      } else {
        return ''
      }
    },
  },
}
</script>
