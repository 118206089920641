var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"filter-container",attrs:{"title":"Filtro de colores"}},[(_vm.isMovile)?_c('div',[_c('div',{staticClass:"filter-tag"},[_c('div',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:('filtre-color'),expression:"'filtre-color'"}],staticClass:"d-flex"},[_c('span',[_vm._v("Colores")]),_c('img',{staticClass:"ms-3",attrs:{"src":require("@/assets/icons/arrow-select.svg"),"alt":""}})]),(_vm.colorsSelected.length > 0)?_c('span',{staticClass:"n-options-filter-select"},[_vm._v(_vm._s(_vm.colorsSelected.length))]):_vm._e()]),_c('b-sidebar',{attrs:{"id":'filtre-color',"no-header":"","width":"100%","shadow":"","right":""},scopedSlots:_vm._u([{key:"default",fn:function({ hide }){return [_c('div',{staticClass:"header-filter-movil"},[_c('h3',[_vm._v("Filtros")]),_c('span',{on:{"click":function($event){_vm.colorsSelected = []}}},[_vm._v("Limpiar")])]),_c('div',{staticClass:"header-filter-info-movil",on:{"click":function($event){_vm.addFilter('')
            hide()}}},[_c('img',{staticClass:"me-3",attrs:{"src":require("@/assets/icons/arrow-left.svg"),"alt":"back arrow"}}),_c('p',{staticClass:"mb-0"},[_vm._v("Colores")])]),_c('div',{staticClass:"box-filter-movil"},_vm._l((_vm.filters),function(filter,index){return _c('div',{key:index,staticClass:"content-filter-color-movil py-3",class:_vm.colorsSelected.includes(filter.id)
                ? 'content-filter-color-select'
                : '',on:{"click":function($event){return _vm.addFilterMovil(filter.id)}}},[_c('div',{staticClass:"box-color-filter",style:('background-color: ' + filter.color_hx)}),_c('span',{staticClass:"mr-4",style:(_vm.colorsSelected.includes(filter.id)
                  ? `font-family: 'PT Sans Bold', sans-serif;`
                  : ''),attrs:{"title":filter.descripcion
                  .toLowerCase()
                  .replace(/^\w/, d => d.toUpperCase())}},[_vm._v(_vm._s(filter.descripcion .toLowerCase() .replace(/^\w/, d => d.toUpperCase())))]),(_vm.colorsSelected.includes(filter.id))?_c('img',{staticClass:"img-check-filter-movil",attrs:{"src":require("@/assets/icons/icon-check-black.svg"),"alt":""}}):_vm._e()])}),0),_c('div',{staticClass:"box-btn-apply-filter-movile"},[_c('button',{staticClass:"btn-apply-filter-movile",on:{"click":function($event){_vm.$store.state.products.showSidebar = false
              _vm.addFilter('')}}},[_vm._v(" Aplicar filtros ")])])]}}],null,false,1076917183)})],1):_c('div',[_c('div',{staticClass:"select-filter",on:{"click":function($event){_vm.showOptions = !_vm.showOptions}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('span',{staticClass:"d-flex filter-tag-title"},[_vm._v("Colores")]),(_vm.colorsSelected.length > 0)?_c('span',{staticClass:"n-options-filter-select-desktop"},[_vm._v(_vm._s(_vm.colorsSelected.length))]):_vm._e()]),_c('img',{attrs:{"src":require("@/assets/icons/arrow-select.svg"),"alt":""}})]),(_vm.showOptions)?_c('div',{staticClass:"display-options-filter",on:{"click":function($event){_vm.clickInside = false}}},[_c('div',{staticClass:"header-filter-option"},[_c('p',[_vm._v(" "+_vm._s(_vm.colorsSelected.length)+" "+_vm._s(_vm.colorsSelected.length == 1 ? 'seleccionado' : 'seleccionados')+" ")]),_c('span',{on:{"click":function($event){_vm.clearFilter()
            _vm.showOptions = false}}},[_vm._v("Limpiar")])]),_vm._l((_vm.filters),function(filter,index){return _c('div',{key:index,staticClass:"content-filter-color py-2",class:_vm.colorsSelected.includes(filter.id)
            ? 'content-filter-color-select'
            : '',on:{"click":function($event){_vm.addFilter(filter.id)
          _vm.showOptions = false}}},[_c('div',{staticClass:"box-color-filter",style:('background-color: ' + filter.color_hx)}),_c('span',{staticClass:"mr-4"},[_vm._v(_vm._s(filter.descripcion .toLowerCase() .replace(/^\w/, d => d.toUpperCase())))]),_c('p',{staticClass:"cantidad-filter"},[_vm._v("("+_vm._s(filter.cantidad)+")")])])})],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }