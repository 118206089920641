<template>
  <b-modal
    v-if="!hideModalNewsletter"
    id="modal-newsletter"
    style="z-index: 999999999999;"
    size="lg"
    body-bg-variant="rgba(0, 13, 80, .5)"
    centered
    hide-footer
    hide-header
    @hide="hideModalTemporarily(3)"
  >
    <div class="d-flex flex-wrap p-3">
      <div
        style="overflow: hidden; position: relative;"
        class="col-lg-5 d-none d-lg-flex"
      >
        <img
          class="img-modal-newslatter"
          :src="
            getImgBanner
              ? getImgBanner
              : '@/assets/img/bg-banner-modal-newslatter-v2.jpg'
          "
        />
      </div>
      <div class="col-12 col-lg-7 ps-0 ps-lg-4 container-form-newslatter-modal">
        <div class="mb-auto w-100 pe-md-4">
          <h2 class="bold text-center w-100 mb-3" style="color: #654062;">
            {{ tituloNewslatter }}
          </h2>
          <p class="fs-6">
            {{ descripcionNewslatter }}
          </p>
          <div class="position-relative mb-4" for="email">
            <input
              id="email"
              v-model="correo"
              class="payment-input w-100 mb-3"
              :class="validateEmailNewslatter ? 'input-error' : ''"
              placeholder="Correo electrónico"
              type="email"
              name="correo"
            />
            <span
              v-if="correo"
              class="newlatter-error-input"
              :style="validateEmailNewslatter ? 'color: red' : ''"
              >correo</span
            >
          </div>
          <div class="position-relative mb-4" for="email">
            <input
              id="name"
              v-model="name"
              class="payment-input w-100 mb-3"
              :class="validateNameNewslatter ? 'input-error' : ''"
              placeholder="Nombre completo"
              type="name"
              name="Nombre completo"
            />
            <span
              v-if="name"
              class="newlatter-error-input"
              :style="validateNameNewslatter ? 'color: red' : ''"
            >
              Nombre completo
            </span>
          </div>
          <div class="input-tel-newslatter w-100 mb-5 position-relative">
            <input
              v-model="tel"
              class="payment-input w-100 ps-3"
              :class="validateTelNewslatter ? 'input-error' : ''"
              placeholder="Celular"
              type="tel"
              name="celular"
            />
            <span
              v-if="tel"
              class="newlatter-error-input"
              :style="validateTelNewslatter ? 'color: red' : ''"
              >Celular</span
            >
          </div>
          <div class="date-newslatter-modal mb-4">
            <label class="label-text-newlatter-birthday"
              >Fecha de cumpleaños</label
            >
            <select
              v-model="day"
              :class="!day && validateDateNewslatter ? 'input-error' : ''"
              class="payment-input me-3"
            >
              <option :value="null">Día</option>
              <option :value="'01'">1</option>
              <option :value="'02'">2</option>
              <option :value="'03'">3</option>
              <option :value="'04'">4</option>
              <option :value="'05'">5</option>
              <option :value="'06'">6</option>
              <option :value="'07'">7</option>
              <option :value="'08'">8</option>
              <option :value="'09'">9</option>
              <option :value="'10'">10</option>
              <option :value="'11'">11</option>
              <option :value="'12'">12</option>
              <option :value="'13'">13</option>
              <option :value="'14'">14</option>
              <option :value="'15'">15</option>
              <option :value="'16'">16</option>
              <option :value="'17'">17</option>
              <option :value="'18'">18</option>
              <option :value="'19'">19</option>
              <option :value="'20'">20</option>
              <option :value="'21'">21</option>
              <option :value="'22'">22</option>
              <option :value="'23'">23</option>
              <option :value="'24'">24</option>
              <option :value="'25'">25</option>
              <option :value="'26'">26</option>
              <option :value="'27'">27</option>
              <option :value="'28'">28</option>
              <option :value="'29'">29</option>
              <option :value="'30'">30</option>
              <option :value="'31'">31</option>
            </select>
            <select
              v-model="month"
              :class="!month && validateDateNewslatter ? 'input-error' : ''"
              class="payment-input ms-3"
            >
              <option :value="null">Mes</option>
              <option :value="'01'">Enero</option>
              <option :value="'02'">Febrero</option>
              <option :value="'03'">Marzo</option>
              <option :value="'04'">Abril</option>
              <option :value="'05'">Mayo</option>
              <option :value="'06'">Junio</option>
              <option :value="'07'">Julio</option>
              <option :value="'08'">Agosto</option>
              <option :value="'09'">Septiembre</option>
              <option :value="'10'">Octubre</option>
              <option :value="'11'">Noviembre</option>
              <option :value="'12'">Diciembre</option>
            </select>
            <span
              v-if="day"
              class="newlatter-error-input"
              :style="!day && validateDateNewslatter ? 'color: red' : ''"
              >Día</span
            >
            <span
              v-if="month"
              class="newlatter-error-input"
              style="left: calc(50% + 1.5rem);"
              :style="!month && validateDateNewslatter ? 'color: red' : ''"
              >Mes</span
            >
          </div>
          <div
            class="d-flex align-items-center justify-content-start w-100 mb-4"
          >
            <label
              class="d-flex mx-0 ms-md-1"
              for="telefono-news-modal"
              style="color: transparent;"
            >
              r
              <input
                id="telefono-news-modal"
                v-model="tyc"
                class="tyc-checkbox"
                type="checkbox"
                checkmark="checkmark"
              />
              <span class="checkmark"></span>
            </label>
            <p class="mb-0 text-start" style="font-size: 12px">
              He leído y acepto la
              <router-link
                style="font-size: 12px; color: #654062;"
                target="_blank"
                :to="{
                  name: 'Politicas',
                  params: { slug: 'politica-de-privacidad' },
                }"
              >
                Política de Privacidad y Tratamiento de datos personales.
              </router-link>
              Acepto recibir novedades y promociones. Aplican
              <router-link
                style="font-size: 12px; color: #654062;"
                target="_blank"
                :to="{
                  name: 'Politicas',
                  params: { slug: 'descuento-suscripcion' },
                }"
              >
                Términos y Condiciones.
              </router-link>
            </p>
          </div>
        </div>
        <div class="text-center w-100 mb-3">
          <span class="msgError">{{ msgerrorNewsLatter }}</span>
        </div>
        <div class="mt-auto col-6 mx-auto">
          <button
            :disabled="!tyc"
            class="btn-primary w-100"
            @click="sendNewsLatter()"
          >
            Suscribirme
          </button>
        </div>
      </div>
    </div>
    <img
      class="btn-close-modal-product-detail"
      src="@/assets/icons/delete-gray-icon.svg"
      alt="boton cerrar"
      @click="$bvModal.hide('modal-newsletter')"
    />
  </b-modal>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { GA4SignUp } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      correo: '',
      name: '',
      validateNameNewslatter: false,
      validateEmailNewslatter: false,
      day: null,
      month: null,
      validateDateNewslatter: false,
      tel: '',
      validateTelNewslatter: false,
      cel_newslatter: false,
      hideModalNewsletter: false,
      getImgBanner: null,
      tyc: false,
    }
  },
  computed: {
    ...mapState('user', [
      'msgerrorNewsLatter',
      'tituloNewslatter',
      'descripcionNewslatter',
    ]),
    /* eslint-disable */
    validateEmail() {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(this.correo)
    },
    validatePhone() {
      const re = /^3[0-9]{9}/
      return re.test(this.tel) && this.tel.length < 11
    },
  },
  created() {
    const itemStr = localStorage.getItem('hideModalNewsletter')
    if (itemStr) {
      const item = JSON.parse(itemStr)
      const now = new Date()
      if (now.getTime() > item.expiry) {
        localStorage.removeItem('hideModalNewsletter')
      } else {
        this.hideModalNewsletter = true
      }
    }
  },
  async mounted() {
    await setTimeout(() => {
      this.$bvModal.show('modal-newsletter')
    }, 4000)
    await this.axios({
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/layouts/get_newsletter',
    }).then(response => {
      this.getImgBanner = response.data.newsletter.imagen
    })
  },
  methods: {
    ...mapActions('user', ['suscribeDerek', 'getInfoNewsLatter']),
    hideModalTemporarily(days) {
      const now = new Date()
      const item = {
        expiry: now.getTime() + days * 24 * 60 * 60 * 1000,
      }
      localStorage.setItem('hideModalNewsletter', JSON.stringify(item))
    },
    async sendNewsLatter() {
      if (
        this.name.length > 2 &&
        this.day &&
        this.month &&
        this.validatePhone &&
        this.validateEmail
      ) {
        this.validateEmailNewslatter = false
        this.validateDateNewslatter = false
        this.validateTelNewslatter = false
        this.validateNameNewslatter = false
        await this.suscribeDerek({
          correo: this.correo,
          name: this.name,
          day: this.day,
          month: this.month,
          sms: 1,
          tel: this.tel,
        }).then(() => {
          if (!this.msgerrorNewsLatter) {
            document.cookie = 'email=' + this.correo
            // DK-NewsLetter
            // window.dataLayer.push({
            //   event: 'DK-NewsLetter',
            //   location: 'modal',
            //   name: this.name,
            //   email: this.correo,
            //   phone: this.tel,
            //   birthday: {
            //     month: this.month,
            //     day: this.day,
            //   },
            //   gender: 'n/a',
            //   tyc: 'accepted',
            //   'DK-NL-status': 'success',
            // })
            this.$bvModal.show('modal-done-newsletter')
            this.$bvModal.hide('modal-newsletter')
            sendFBQEvent(this, 'Subscribe', {})
            GA4SignUp('NewsLatter')
            this.correo = ''
            this.stepNewslatter += 1
          }
        })
      } else {
        this.validateEmailNewslatter = !this.validateEmail
        this.validateNameNewslatter = !(this.name.length > 2)
        this.validateDateNewslatter = !(this.day && this.month)
        this.validateTelNewslatter = !this.validatePhone
      }
    },
  },
}
</script>
