<template>
  <div v-if="isMovile">
    <div class="filter-tag">
      <div v-b-toggle="'filtre-short'" class="d-flex">
        <span>Ordenar</span>
        <img class="ms-3" src="@/assets/icons/arrow-select.svg" alt="" />
      </div>
      <span v-if="selectFilter" class="n-options-filter-select">1</span>
    </div>
    <b-sidebar :id="'filtre-short'" no-header width="100%" shadow right>
      <template v-slot:default="{ hide }">
        <div class="header-filter-movil">
          <h3>Ordenamiento</h3>
          <span @click="clearRoute()">Limpiar</span>
        </div>
        <div
          class="header-filter-info-movil"
          @click="
            getRoute()
            hide()
          "
        >
          <img class="me-3" src="@/assets/icons/arrow-left.svg" alt="" />
          <p class="mb-0">Ordenar</p>
        </div>
        <div class="box-filter-movil">
          <div
            class="content-filter-order-by"
            :style="selectFilter == 'date' ? 'background-color: #f2f2f2' : ''"
            @click="selectFilter = 'date'"
          >
            <div class="item-filter-order-by py-2">
              <span
                title="Novedades"
                :style="
                  selectFilter == 'date'
                    ? `font-family: 'PT Sans Bold', sans-serif;`
                    : ''
                "
                >Novedades</span
              >
              <img
                v-if="selectFilter == 'date'"
                class="img-check-filter-movil"
                style="transform: translate(0);"
                src="@/assets/icons/icon-check-black.svg"
                alt=""
              />
            </div>
          </div>
          <div
            class="content-filter-order-by"
            :style="
              selectFilter == 'price_asc' ? 'background-color: #f2f2f2' : ''
            "
            @click="selectFilter = 'price_asc'"
          >
            <div class="item-filter-order-by py-2">
              <span
                title="Precio más bajo"
                :style="
                  selectFilter == 'price_asc'
                    ? `font-family: 'PT Sans Bold', sans-serif;`
                    : ''
                "
                >Precio más bajo</span
              >
              <img
                v-if="selectFilter == 'price_asc'"
                class="img-check-filter-movil"
                style="transform: translate(0);"
                src="@/assets/icons/icon-check-black.svg"
                alt=""
              />
            </div>
          </div>
          <div
            class="content-filter-order-by"
            :style="
              selectFilter == 'price_desc' ? 'background-color: #f2f2f2' : ''
            "
            @click="selectFilter = 'price_desc'"
          >
            <div class="item-filter-order-by py-2">
              <span
                title="Precio más alto"
                :style="
                  selectFilter == 'price_desc'
                    ? `font-family: 'PT Sans Bold', sans-serif;`
                    : ''
                "
                >Precio más alto</span
              >
              <img
                v-if="selectFilter == 'price_desc'"
                class="img-check-filter-movil"
                style="transform: translate(0);"
                src="@/assets/icons/icon-check-black.svg"
                alt=""
              />
            </div>
          </div>
          <div
            class="content-filter-order-by"
            :style="
              selectFilter == 'best_seller' ? 'background-color: #f2f2f2' : ''
            "
            @click="selectFilter = 'best_seller'"
          >
            <div class="item-filter-order-by py-2">
              <span
                title="Lo más vendido"
                :style="
                  selectFilter == 'best_seller'
                    ? `font-family: 'PT Sans Bold', sans-serif;`
                    : ''
                "
                >Lo más vendido</span
              >
              <img
                v-if="selectFilter == 'best_seller'"
                class="img-check-filter-movil"
                style="transform: translate(0);"
                src="@/assets/icons/icon-check-black.svg"
                alt=""
              />
            </div>
          </div>
          <div
            class="content-filter-order-by"
            :style="
              selectFilter == 'best_visit' ? 'background-color: #f2f2f2' : ''
            "
            @click="selectFilter = 'best_visit'"
          >
            <div class="item-filter-order-by py-2">
              <span
                title="Lo más visto"
                :style="
                  selectFilter == 'best_visit'
                    ? `font-family: 'PT Sans Bold', sans-serif;`
                    : ''
                "
                >Lo más visto</span
              >
              <img
                v-if="selectFilter == 'best_visit'"
                class="img-check-filter-movil"
                style="transform: translate(0);"
                src="@/assets/icons/icon-check-black.svg"
                alt=""
              />
            </div>
          </div>
          <div
            class="content-filter-order-by"
            :style="selectFilter == 'sale' ? 'background-color: #f2f2f2' : ''"
            @click="selectFilter = 'sale'"
          >
            <div class="item-filter-order-by py-2">
              <span
                title="Sale"
                :style="
                  selectFilter == 'sale'
                    ? `font-family: 'PT Sans Bold', sans-serif;`
                    : ''
                "
                >Sale</span
              >
              <img
                v-if="selectFilter == 'sale'"
                class="img-check-filter-movil"
                style="transform: translate(0);"
                src="@/assets/icons/icon-check-black.svg"
                alt=""
              />
            </div>
          </div>
          <div class="px-4 mt-5">
            <button
              class="btn-apply-filter-movile"
              @click="
                getRoute()
                hide()
              "
            >
              Aplicar filtro
            </button>
          </div>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
  props: {
    isMovile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selectFilter: '',
    }
  },
  computed: {
    ...mapState('products', ['showSidebar']),
  },
  watch: {
    $route() {
      if (this.$route.query.ordenar) {
        this.selectFilter = this.$route.query.ordenar
      }
    },
    showSidebar() {
      if (this.selectFilter) {
        this.getRoute()
      }
    },
  },
  mounted() {
    if (this.$route.query.ordenar) {
      this.selectFilter = this.$route.query.ordenar
    }
  },
  methods: {
    async getRoute() {
      if (this.selectFilter) {
        let query = Object.assign({}, this.$route.query)
        query.ordenar = this.selectFilter
        await this.$router.push({ query: query }).catch(() => {})
      } else {
        this.selectFilter = ''
      }
    },
    async clearRoute() {
      this.selectFilter = ''
      let query = Object.assign({}, this.$route.query)
      if (query.ordenar) {
        delete query.ordenar
        await this.$router.push({ query: query }).catch(() => {})
      }
    },
  },
}
</script>
