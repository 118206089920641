<template>
  <div class="filter-container" title="Filtro de colores">
    <div v-if="isMovile">
      <div class="filter-tag">
        <div v-b-toggle="'filtre-color'" class="d-flex">
          <span>Colores</span>
          <img class="ms-3" src="@/assets/icons/arrow-select.svg" alt="" />
        </div>
        <span
          v-if="colorsSelected.length > 0"
          class="n-options-filter-select"
          >{{ colorsSelected.length }}</span
        >
      </div>
      <b-sidebar :id="'filtre-color'" no-header width="100%" shadow right>
        <template v-slot:default="{ hide }">
          <div class="header-filter-movil">
            <h3>Filtros</h3>
            <span @click="colorsSelected = []">Limpiar</span>
          </div>
          <div
            class="header-filter-info-movil"
            @click="
              addFilter('')
              hide()
            "
          >
            <img
              class="me-3"
              src="@/assets/icons/arrow-left.svg"
              alt="back arrow"
            />
            <p class="mb-0">Colores</p>
          </div>
          <div class="box-filter-movil">
            <div
              v-for="(filter, index) in filters"
              :key="index"
              class="content-filter-color-movil py-3"
              :class="
                colorsSelected.includes(filter.id)
                  ? 'content-filter-color-select'
                  : ''
              "
              @click="addFilterMovil(filter.id)"
            >
              <div
                class="box-color-filter"
                :style="'background-color: ' + filter.color_hx"
              />
              <span
                class="mr-4"
                :style="
                  colorsSelected.includes(filter.id)
                    ? `font-family: 'PT Sans Bold', sans-serif;`
                    : ''
                "
                :title="
                  filter.descripcion
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                "
                >{{
                  filter.descripcion
                    .toLowerCase()
                    .replace(/^\w/, d => d.toUpperCase())
                }}</span
              >
              <img
                v-if="colorsSelected.includes(filter.id)"
                class="img-check-filter-movil"
                src="@/assets/icons/icon-check-black.svg"
                alt=""
              />
            </div>
          </div>
          <div class="box-btn-apply-filter-movile">
            <button
              class="btn-apply-filter-movile"
              @click="
                $store.state.products.showSidebar = false
                addFilter('')
              "
            >
              Aplicar filtros
            </button>
          </div>
        </template>
      </b-sidebar>
    </div>
    <div v-else>
      <div class="select-filter" @click="showOptions = !showOptions">
        <div class="d-flex align-items-center">
          <span class="d-flex filter-tag-title">Colores</span>
          <span
            v-if="colorsSelected.length > 0"
            class="n-options-filter-select-desktop"
            >{{ colorsSelected.length }}</span
          >
        </div>
        <img src="@/assets/icons/arrow-select.svg" alt="" />
      </div>
      <div
        v-if="showOptions"
        class="display-options-filter"
        @click="clickInside = false"
      >
        <div class="header-filter-option">
          <p>
            {{ colorsSelected.length }}
            {{ colorsSelected.length == 1 ? 'seleccionado' : 'seleccionados' }}
          </p>
          <span
            @click="
              clearFilter()
              showOptions = false
            "
            >Limpiar</span
          >
        </div>
        <div
          v-for="(filter, index) in filters"
          :key="index"
          class="content-filter-color py-2"
          :class="
            colorsSelected.includes(filter.id)
              ? 'content-filter-color-select'
              : ''
          "
          @click="
            addFilter(filter.id)
            showOptions = false
          "
        >
          <div
            class="box-color-filter"
            :style="'background-color: ' + filter.color_hx"
          />
          <span class="mr-4">{{
            filter.descripcion
              .toLowerCase()
              .replace(/^\w/, d => d.toUpperCase())
          }}</span>
          <p class="cantidad-filter">({{ filter.cantidad }})</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
    isMovile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      colorsSelected: [],
      showOptions: false,
      clickInside: false,
    }
  },
  watch: {
    showOptions() {
      if (this.showOptions) {
        setTimeout(() => {
          this.clickInside = true
        }, 100)
      } else {
        this.clickInside = false
      }
    },
    clickInside() {
      if (!this.clickInside && this.showOptions) {
        setTimeout(() => {
          this.clickInside = true
        }, 100)
      }
    },
    colorsSelected() {
      this.$emit('updateFilterMovil', this.colorsSelected)
    },
    $route() {
      window.scrollTo(0, 0)
      this.colorsSelected = this.$route.query.colores
        ? this.$route.query.colores.split(',')
        : []
    },
  },
  mounted() {
    this.colorsSelected = this.$route.query.colores
      ? this.$route.query.colores.split(',')
      : []
    let elThis = this
    document.addEventListener('click', () => {
      if (elThis.showOptions && elThis.clickInside) {
        elThis.showOptions = false
      }
    })
  },
  methods: {
    async clearFilter() {
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.colores
      await this.$router.push({ params: params, query: query }).catch(() => {})
    },
    async addFilter(id) {
      // si quiere que se oculte el filtro en desktop poner
      // this.showOptions = false
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.page
      if (this.isMovile) {
        if (this.colorsSelected.length == 0) {
          delete query.colores
        } else {
          query.colores = this.colorsSelected.toString()
        }
      } else {
        if (this.colorsSelected.includes(id)) {
          this.colorsSelected = this.colorsSelected.filter(item => item !== id)
        } else {
          this.colorsSelected.push(id)
        }
        if (this.colorsSelected.length == 0) {
          delete query.colores
        } else {
          query.colores = this.colorsSelected.toString()
        }
      }
      await this.$router.push({ params: params, query: query }).catch(() => {})
    },
    async addFilterMovil(id) {
      if (this.colorsSelected.includes(id)) {
        this.colorsSelected = this.colorsSelected.filter(item => item !== id)
      } else {
        this.colorsSelected.push(id)
      }
    },
  },
}
</script>
