var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.$store.state.products.showSidebar ? 'z-index: 2000;' : ''),attrs:{"id":"nav-filters-desktop"}},[(!_vm.loaderFilters)?_c('div',{staticClass:"filters-store-movil-content ps-3 ps-md-0"},[(_vm.atajos.length > 0)?_c('div',{key:_vm.atajos.length + _vm.$route.fullPath,staticClass:"container-carousel-atajos"},[_c('carousel',{attrs:{"dots":false,"lazy-load":true,"nav":false,"rewind":false,"auto-width":true}},_vm._l((_vm.atajos),function(item,index){return _c('div',{key:index + _vm.$route.fullPath,staticClass:"item-carousel-category"},[_c('div',{staticClass:"carousel-category"},[_c('router-link',{style:('/' + item.slug == _vm.$route.path ||
                (_vm.$route.query[item.query_name] &&
                  _vm.$route.query[item.query_name]
                    .split(',')
                    .includes(item.slug))
                  ? 'font-family: `PT Sans Bold`, sans-serif;'
                  : ''),attrs:{"to":item.query_name
                  ? { query: { [item.query_name]: item.slug } }
                  : { path: item.slug }}},[_vm._v(" "+_vm._s(item.text.toLowerCase().replace(/^\w/, d => d.toUpperCase())))])],1)])}),0)],1):_vm._e(),_c('div',{staticClass:"filters-store-movil ms-auto me-3"},[_c('button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.sidebar-menu-movil",modifiers:{"sidebar-menu-movil":true}}],on:{"click":function($event){_vm.showMoreFilters = true
          _vm.$store.state.displayBtnHelp = false}}},[_c('img',{staticClass:"pe-2",staticStyle:{"height":"25px"},attrs:{"src":require("@/assets/icons/icon-filter.svg"),"alt":"icono filtrar"}}),_vm._v(" Filtrar ")])]),_c('show-filters-movile')],1):_c('div',{staticClass:"skeleton-filters"},[_c('carousel',{staticClass:"ps-2 ps-md-0",staticStyle:{"width":"calc(100% - 146px)"},attrs:{"dots":false,"lazy-load":true,"nav":false,"rewind":false,"auto-width":true}},_vm._l((13),function(index){return _c('b-skeleton',{key:index,staticClass:"me-2",attrs:{"width":index % 2 == 0 ? '80px' : '120px',"height":"57px"}})}),1),_c('b-skeleton',{staticClass:"ms-4 me-3",attrs:{"width":"106px","height":"57px"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }