<template>
  <div class="filter-container" title="Filtro de tallas">
    <div v-if="isMovile">
      <div class="filter-tag">
        <div v-b-toggle="'filtre-talla'" class="d-flex">
          <span>Tallas</span>
          <img class="ms-3" src="@/assets/icons/arrow-select.svg" alt="" />
        </div>
        <span v-if="sizeSelected.length > 0" class="n-options-filter-select">{{
          sizeSelected.length
        }}</span>
      </div>
      <b-sidebar :id="'filtre-talla'" right no-header width="100%" shadow>
        <template v-slot:default="{ hide }">
          <div class="header-filter-movil">
            <h3>Filtros</h3>
            <span @click="sizeSelected = []"
              >Limpiar
              <!-- <img @click="hide" class="close-filters-movile" src="@/assets/icons/delete-gray-icon.svg" alt="close sidebar"> --></span
            >
          </div>
          <div
            class="header-filter-info-movil"
            @click="
              addFilter('')
              hide()
            "
          >
            <img class="me-3" src="@/assets/icons/arrow-left.svg" alt="" />
            <p class="mb-0">Tallas</p>
          </div>
          <div class="box-filter-movil">
            <div
              v-for="(filter, index) in filters"
              :key="index"
              class="filter-one-level-router"
              :class="
                sizeSelected.includes(filter.id)
                  ? 'content-filter-color-select'
                  : ''
              "
              @click="addFilterMovil(filter.id)"
            >
              <div class="filter-tag pe-3">
                <span
                  :style="
                    sizeSelected.includes(filter.id)
                      ? `font-family: 'PT Sans Bold', sans-serif;`
                      : ''
                  "
                  :title="filter.descripcion.toUpperCase()"
                  >{{ filter.descripcion.toUpperCase() }}</span
                >
                <img
                  v-if="sizeSelected.includes(filter.id)"
                  class="img-check-filter-movil"
                  style="transform: translate(0);"
                  src="@/assets/icons/icon-check-black.svg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div class="box-btn-apply-filter-movile">
            <button
              class="btn-apply-filter-movile"
              @click="
                $store.state.products.showSidebar = false
                addFilter('')
              "
            >
              Aplicar filtros
            </button>
          </div>
        </template>
      </b-sidebar>
    </div>
    <div v-else>
      <div class="select-filter" @click="showOptions = !showOptions">
        <span class="d-flex"
          >Talla
          <span
            v-if="sizeSelected.length > 0"
            class="n-options-filter-select-desktop"
            >{{ sizeSelected.length }}</span
          ></span
        >
        <img src="@/assets/icons/arrow-select.svg" alt="" />
      </div>
      <div
        v-if="showOptions"
        class="display-options-filter"
        @click="clickInside = false"
      >
        <div class="header-filter-option">
          <p>
            {{ sizeSelected.length }}
            {{ sizeSelected.length == 1 ? 'seleccionado' : 'seleccionados' }}
          </p>
          <span
            @click="
              clearFilter()
              showOptions = false
            "
            >Limpiar</span
          >
        </div>
        <div class="content-filter-sizes">
          <span
            v-for="(filter, index) in filters"
            :key="index"
            class="size-filter"
            :class="
              sizeSelected.includes(filter.id) ? 'size-filter-select' : ''
            "
            @click="
              addFilter(filter.id)
              showOptions = false
            "
            >{{ filter.descripcion }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    filters: {
      type: Array,
      required: false,
      default: () => [],
    },
    isMovile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      sizeSelected: [],
      tempSizeSelected: [],
      showOptions: false,
      clickInside: false,
    }
  },
  watch: {
    showOptions() {
      if (this.showOptions) {
        setTimeout(() => {
          this.clickInside = true
        }, 100)
      } else {
        this.clickInside = false
      }
    },
    clickInside() {
      if (!this.clickInside && this.showOptions) {
        setTimeout(() => {
          this.clickInside = true
        }, 100)
      }
    },
    sizeSelected() {
      this.$emit('updateFilterMovil', this.sizeSelected)
    },
    $route() {
      window.scrollTo(0, 0)
      this.sizeSelected = this.$route.query.tallas
        ? this.$route.query.tallas.split(',')
        : []
      this.tempSizeSelected = this.$route.query.tallas
        ? this.$route.query.tallas.split(',')
        : []
    },
  },
  mounted() {
    this.sizeSelected = this.$route.query.tallas
      ? this.$route.query.tallas.split(',')
      : []
    this.tempSizeSelected = this.$route.query.tallas
      ? this.$route.query.tallas.split(',')
      : []
    let elThis = this
    document.addEventListener('click', () => {
      if (elThis.showOptions && elThis.clickInside) {
        elThis.showOptions = false
      }
    })
  },
  methods: {
    async addFilterMovil(id) {
      if (this.sizeSelected.includes(id)) {
        this.sizeSelected = this.sizeSelected.filter(item => item !== id)
        this.tempSizeSelected = this.sizeSelected.filter(item => item !== id)
      } else {
        this.sizeSelected.push(id)
        this.tempSizeSelected.push(id)
      }
    },
    async clearFilter() {
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.tallas
      await this.$router.push({ params: params, query: query }).catch(() => {})
    },
    async addFilter(id) {
      if (this.sizeSelected.toString() != this.tempSizeSelected.toString()) {
        let query = Object.assign({}, this.$route.query)
        let params = Object.assign({}, this.$route.params)
        delete query.page
        if (this.isMovile) {
          if (this.sizeSelected.length == 0) {
            delete query.tallas
          } else {
            query.tallas = this.sizeSelected.toString()
          }
        } else {
          if (this.sizeSelected.includes(id)) {
            this.sizeSelected = this.sizeSelected.filter(item => item !== id)
          } else {
            this.sizeSelected.push(id)
          }
          if (this.sizeSelected.length == 0) {
            delete query.tallas
          } else {
            query.tallas = this.sizeSelected.toString()
          }
        }
        await this.$router
          .push({ params: params, query: query })
          .catch(() => {})
      }
    },
  },
}
</script>
