<template>
  <button
    v-if="dataProduct"
    aria-label="añadir a favoritos"
    @click="addFavorite()"
  >
    <svg
      id="Componente_1_9"
      data-name="Componente 1 – 9"
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <rect
        id="Rectángulo_2051"
        data-name="Rectángulo 2051"
        width="30"
        height="30"
        fill="#fff"
      />
      <path
        v-if="isFav"
        id="Icon_feather-heart"
        data-name="Icon feather-heart"
        d="M20.781,6.039a5.262,5.262,0,0,0-7.444,0L12.323,7.054,11.309,6.039a5.264,5.264,0,1,0-7.444,7.444L4.879,14.5l7.444,7.444L19.767,14.5l1.014-1.014a5.262,5.262,0,0,0,0-7.444Z"
        transform="translate(3.177 2.002)"
        fill="#8dcfd7"
      />
      <path
        v-else
        id="Icon_feather-heart"
        data-name="Icon feather-heart"
        fill="none"
        d="M20.781,6.039a5.262,5.262,0,0,0-7.444,0L12.323,7.054,11.309,6.039a5.264,5.264,0,1,0-7.444,7.444L4.879,14.5l7.444,7.444L19.767,14.5l1.014-1.014a5.262,5.262,0,0,0,0-7.444Z"
        transform="translate(3.177 2.002)"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.6"
      />
    </svg>
  </button>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { sendFBQEvent } from '@/fbq'
export default {
  props: {
    dataProduct: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  computed: {
    ...mapState('products', ['productWhislist']),
    isFav() {
      return this.productWhislist.find(
        e =>
          e.producto_sku + e.color ==
          this.dataProduct.producto_sku + this.dataProduct.color,
      )
    },
  },
  methods: {
    ...mapActions('products', ['addToFavorite', 'deleteFromFavorite']),
    async addFavorite() {
      if (this.isFav) {
        await this.deleteFromFavorite({
          sku: this.dataProduct.producto_sku,
          color: this.dataProduct.color,
        })
      } else {
        await this.addToFavorite({
          sku: this.dataProduct.producto_sku,
          color: this.dataProduct.color,
        })
        sendFBQEvent(this, 'AddToWishlist', {
          product_name: this.dataProduct.titulo,
          content_type: 'product_group',
          contents: '1',
          currency: 'COP',
          product_id: this.dataProduct.producto_sku,
          product_ids: [this.dataProduct.producto_sku],
          value: this.dataProduct.producto_precio,
        })
      }
      // }
    },
  },
}
</script>
